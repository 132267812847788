<template>
  <div>
    <b-form>
      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Số giấy phép lao động/xác nhận không thuộc diện cấp giấy phép lao động</label>
            <b-form-input
              v-model="dataGeneral.licenseNumber"
            />
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label for="code">Vị trí công việc<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Vị trí công việc"
              rules="required"
              :custom-messages="{required: `Vị trí công việc là bắt buộc`}"
            >
              <v-select
                v-model="dataGeneral.foreignerJobPosition"
                :reduce="item => item.key"
                label="name"
                :class="{'is-invalid':errors.length > 0 }"
                :options="foreignerJobPosition || []"
                placeholder="Lựa chọn Vị trí công việc"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label for="code">Nghề công việc<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Nghề công việc"
              rules="required"
              :custom-messages="{required: `Nghề công việc là bắt buộc`}"
            >
              <v-select
                v-model="dataGeneral.foreignerJob"
                :reduce="item => item.key"
                label="name"
                :class="{'is-invalid':errors.length > 0 }"
                :options="foreignerJob || []"
                placeholder="Lựa chọn Nghề công việc"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label for="code">Hình thức làm việc<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Hình thức làm việc"
              rules="required"
              :custom-messages="{required: `Hình thức làm việc là bắt buộc`}"
            >
              <v-select
                v-model="dataGeneral.foreignerForm"
                :reduce="item => item.key"
                label="name"
                :class="{'is-invalid':errors.length > 0 }"
                :options="foreignerForm || []"
                placeholder="Lựa chọn Hình thức làm việc"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <h3 class="d-flex align-items-center">
        <feather-icon
          size="20"
          icon="MapPinIcon"
          class="mr-75"
        />
        <span>
          Nơi làm việc
        </span>
      </h3>
      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label for="code">Tên doanh nghiệp, tổ chức<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Tên doanh nghiệp, tổ chức"
              rules="required"
              :custom-messages="{required: `Tên doanh nghiệp, tổ chức là bắt buộc`}"
            >
              <v-select
                v-model="dataGeneral.bussinessId"
                :reduce="item => item.id"
                label="name"
                :class="{'is-invalid':errors.length > 0 }"
                :options="dataAllBusiness || []"
                placeholder="Lựa chọn Vị trí công việc"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <h3 class="d-flex align-items-center">
        <feather-icon
          size="20"
          icon="BriefcaseIcon"
          class="mr-75"
        />
        <span>
          Thời hạn làm việc
        </span>
      </h3>
      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Ngày bắt đầu<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Ngày bắt đầu"
              rules="required"
              :custom-messages="{required: `Ngày bắt đầu là bắt buộc`}"
            >
              <date-time-picker
                v-model="dataGeneral.dateStart"
                :state="errors.length > 0 ? false : null"
                :disabledInput="true"
                :disabled="disableProp"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Ngày kết thúc<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Ngày kết thúc"
              rules="required"
              :custom-messages="{required: `Ngày kết thúc là bắt buộc`}"
            >
              <date-time-picker
                v-model="dataGeneral.dateEnd"
                :disabledInput="true"
                :state="errors.length > 0 ? false : null"
                :disabled="disableProp"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, numeric, email,
} from '@validations'
import vSelect from 'vue-select'
import enumSelect from '../EnumSelected'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    DateTimePicker,
    ValidationProvider,
  },

  props: {
    dataGeneral: {
      type: Object,
      default: _ => {},
    },
  },

  data() {
    return {
      disableProp: false,
      foreignerJobPosition: enumSelect.foreignerJobPosition,
      foreignerJob: enumSelect.foreignerJob,
      foreignerForm: enumSelect.foreignerForm,
      isSelfEmployment: false,
    }
  },

  computed: {
    ...mapGetters('performTraining', ['dataAllBusiness']),
  },

  mounted() {
    this.fetchDataComboboxAllBusiness(this.isSelfEmployment)
  },

  methods: {
    ...mapActions('performTraining', ['fetchDataComboboxAllBusiness']),
  },
}
</script>

<style>

</style>
