var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Số giấy phép lao động/xác nhận không thuộc diện cấp giấy phép lao động")]),_c('b-form-input',{model:{value:(_vm.dataGeneral.licenseNumber),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "licenseNumber", $$v)},expression:"dataGeneral.licenseNumber"}})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Vị trí công việc"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Vị trí công việc","rules":"required","custom-messages":{required: "Vị trí công việc là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (item) { return item.key; },"label":"name","options":_vm.foreignerJobPosition || [],"placeholder":"Lựa chọn Vị trí công việc"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataGeneral.foreignerJobPosition),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "foreignerJobPosition", $$v)},expression:"dataGeneral.foreignerJobPosition"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Nghề công việc"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Nghề công việc","rules":"required","custom-messages":{required: "Nghề công việc là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (item) { return item.key; },"label":"name","options":_vm.foreignerJob || [],"placeholder":"Lựa chọn Nghề công việc"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataGeneral.foreignerJob),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "foreignerJob", $$v)},expression:"dataGeneral.foreignerJob"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Hình thức làm việc"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Hình thức làm việc","rules":"required","custom-messages":{required: "Hình thức làm việc là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (item) { return item.key; },"label":"name","options":_vm.foreignerForm || [],"placeholder":"Lựa chọn Hình thức làm việc"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataGeneral.foreignerForm),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "foreignerForm", $$v)},expression:"dataGeneral.foreignerForm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h3',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"size":"20","icon":"MapPinIcon"}}),_c('span',[_vm._v(" Nơi làm việc ")])],1),_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Tên doanh nghiệp, tổ chức"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Tên doanh nghiệp, tổ chức","rules":"required","custom-messages":{required: "Tên doanh nghiệp, tổ chức là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (item) { return item.id; },"label":"name","options":_vm.dataAllBusiness || [],"placeholder":"Lựa chọn Vị trí công việc"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataGeneral.bussinessId),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "bussinessId", $$v)},expression:"dataGeneral.bussinessId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h3',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"size":"20","icon":"BriefcaseIcon"}}),_c('span',[_vm._v(" Thời hạn làm việc ")])],1),_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Ngày bắt đầu"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Ngày bắt đầu","rules":"required","custom-messages":{required: "Ngày bắt đầu là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"state":errors.length > 0 ? false : null,"disabledInput":true,"disabled":_vm.disableProp},model:{value:(_vm.dataGeneral.dateStart),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "dateStart", $$v)},expression:"dataGeneral.dateStart"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"12","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Ngày kết thúc"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Ngày kết thúc","rules":"required","custom-messages":{required: "Ngày kết thúc là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"disabledInput":true,"state":errors.length > 0 ? false : null,"disabled":_vm.disableProp},model:{value:(_vm.dataGeneral.dateEnd),callback:function ($$v) {_vm.$set(_vm.dataGeneral, "dateEnd", $$v)},expression:"dataGeneral.dateEnd"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }